
<template>
  <div class="main" :class="{ isblack: store.state.isMenuOpen }">
    <HelperBox class="main__helperbox" />
    <div class="main__nav">
      <TheNav />
    </div>
    <div id="main__content" ref="appDom">
      <div class="page page__home ">
        <HeaderWelcome />
        <IntroHeaderVideo />
        <about/>
        <markenerfahrung />
        <leistungen />
        <firmen />
        <adserver/>
        <preise />
        <TheFooter class="footer_section" />

      </div>
    </div>
  </div>
  <TheOverlayMain v-if="getOverlayState"></TheOverlayMain>
</template>

<script>


import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import {
  nextTick,
  computed,
  watchEffect,
  onMounted,
  onUpdated,
  ref,
} from "vue";

import { useStore } from "vuex";
import TheNav from "@/components/common/navigation/TheNav";
import TheOverlayMain from "@/components/common/overlay/TheOverlayMain";
import HelperBox from "@/components/helpers/HelperBox";
import TheFooter from "@/components/common/footer/TheFooter.vue";

import IntroHeaderVideo from '@/components/common/header/IntroHeaderVideo.vue';
import HeaderWelcome from "@/components/common/header/HeaderWelcome.vue";
import About from '@/components/common/about/About.vue';
import Markenerfahrung from '@/components/common/markenerfahrung/markenerfahrung.vue';
import Leistungen from '@/components/common/leistungen/leistungen.vue';
import Firmen from '@/components/common/firmen/Firmen.vue';
import Adserver from '@/components/common/adserver/Adserver.vue';

import Preise from '@/components/common/preise/Preise.vue'

export default {
  components: {
    // IntroBlockDevider,
    TheFooter,
    IntroHeaderVideo,
    TheNav, TheOverlayMain, HelperBox,
    HeaderWelcome,
    About,
    Markenerfahrung,
    Leistungen,
    Firmen,
    Adserver,
    Preise
  },

  setup() {
    const DOMLOADED = ref(true);
    const checkingApp = ref(true);
    const store = useStore();
    // lets define some computed const to watch things. so its more readble
    const getOverlayState = computed(() => store.state.isOverlayOpen);
    const getMenuState = computed(() => store.state.isMenuOpen);

    const appDom = ref(null);

    // function resetApp() {
    //   console.log("appDom = ", appDom);
    //   appDom.value.style.height = 0;
    //   appDom.value.style.visibility = "hidden";
    //   appDom.value.style.overflow = "hidden";
    // }
    function updateScrollTrigger() {
      console.error("updateScrollTrigger!");
      if (checkingApp.value) {
        console.error("INSIDE!");
        checkingApp.value = false;
        setTimeout(function () {
          appDom.value.style.visibility = "visible";
          appDom.value.style.height = "inital";
          appDom.value.style.overflow = "visible";

          appDom.value.style.visibility = "visible";
          ScrollTrigger.getAll().forEach((element) => {
            element.update();
            element.refresh();
          });
        }, 100);
      }
    }
    nextTick(() => {
      console.log("nextTick MAIN <-----");
      updateScrollTrigger();
    });

    watchEffect(() => {
      if (store.state.isOverlayOpen) {
        // document.body.style.overflow = "hidden";
      } else {
        // document.body.style.overflow = "auto";
      }
    });

    onUpdated(() => {
      console.log("onUpdated MAIN <-----");
      checkingApp.value = true;
      // resetApp();
      updateScrollTrigger();
    });
    onMounted(() => {
      console.log("onMounted MAIN <-----");
      document.addEventListener("DOMContentLoaded", () => {
        alert("DOMLOADED MAIN<-----------");
        DOMLOADED.value = true;
        // resetApp();
        updateScrollTrigger();
      });
      window.addEventListener("load", function () {
        console.log("LOADED MAIN<-----------");
      });
      // resetApp();
      updateScrollTrigger();
    });
    return {
      store,
      getOverlayState,
      getMenuState,
      checkingApp,
      appDom,
      updateScrollTrigger,
    };
  },
};
</script>

