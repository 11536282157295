<template>
  <div class="video">
    <video
      ref="vid"
    class="video__element"
      :src="videopath"
      autoplay
      muted
      loop
      playsinline
      webkit-playsinline
    ></video>
  </div>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";
import gsap from "gsap";

import { onMounted, ref } from 'vue';
export default {
  props: { videopath: String },
  setup() {
    const { prefixPath } = getPathFunction();
    const vid = ref(null);
    onMounted(() => {

      vid.value.addEventListener('canplay', function () {
        gsap.to(vid.value, 0.2, { opacity: 1 });
        vid.value.play();
      })
    })
    return { prefixPath, ref ,vid};
  },
};
</script>

<style lang="scss">
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}
.video__element{
  opacity: 0;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
