<template>
  <div class="swipernb">
    <svg
    ref="swiperIcon"
      class="swipernb__icon"
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 216.2 381"
      style="enable-background:new 0 0 216.2 381;"
      xml:space="preserve"

      @mouseenter="mouseenter"
      @mouseleave="mouseleave"
    >
      <path class="swipernb__fill" d="M216.2,190.5L0,381V0L216.2,190.5z" />
    </svg>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { gsap } from 'gsap';
export default {
  setup() {

    const swiperIcon = ref(null);

    function mouseenter() {
      gsap.to(swiperIcon.value, 0.1,{
        scale: 1.2, opacity:0.5,
      })
      
    }
    function mouseleave() {
      gsap.to(swiperIcon.value, 0.2,{
        scale: 1, rotation:0.02, opacity:1,
      })
      
    }
    onMounted(() => {
    })

    return{mouseenter,swiperIcon,mouseleave}
  }
};
</script>

<style lang="scss">
.swipernb {
  cursor: pointer;
  &.prev{
    rotate: 180deg;
  }
}
.swipernb__icon {
  width: 100%;
  height: 100%;
}
.swipernb__fill {
  fill: black;
}

.swipernb{
  cursor: pointer;
}

</style>
