<template>
    <div class="c__container c__container--rezension" id="rezension">
        <div class="c__flex c__flex--rezension">
            <div class="c__box c__box--rezension">
                <div class="rezension__wrapper c__wrapper">
                    <div class="lblock__h1 lblock__h1--black text--left">Rezensionen</div>
                    <div class="lblock__content text-left lblock__content--rezension">
                        <div class="rezension__container">
                            <RezensionElement v-for="(rezension, index) in rezensionen" :key="index">
                                <template v-slot:rezensionselement__h1>
                                    {{ rezension.hl }}
                                </template>
                                <template v-slot:rezensionselement__h2>
                                    {{ rezension.sl }}
                                </template>
                                <template v-slot:rezensionselement__p>
                                    {{ rezension.p }}
                                </template>

                            </RezensionElement>
                        </div>



                    </div>
                </div>
            </div>
        </div>
        <div class="c__flex c__flex--preise" id="preise">
            <div class="c__box c__box--preise">
                <div class="preise__wrapper c__wrapper">
                    <div class="lblock__h1 lblock__h1--black text--left">Preise</div>
                    <div class="lblock__content text-left">

                        <!-- <div class="lblock__h2 lblock__h2--white">Wir lieben Es</div> -->
                        <div class="lblock__p lblock__p--black text-left">

                            Unsere Preise richten sich nach den spezifischen Anforderungen und Leistungen unserer Kunden.
                            Die Produktion von HTML5 Ads wird zu Festpreisen angeboten, um Transparenz und Klarheit zu
                            gewährleisten. Für kreative Leistungen wie die Gestaltung von Werbematerialien, Videoschnitt und
                            andere zeitlich basierte Arbeiten wird ein Stundenpreis berechnet. Wir sind bestrebt, faire und
                            wettbewerbsfähige Preise anzubieten, die unseren Kunden einen Mehrwert bieten und gleichzeitig
                            unsere hochwertigen Dienstleistungen widerspiegeln. </div>
                        <div class="btn__container">

                            <AppButton class="introb1__btn" :action="'email'">Hol dir ein Angebot!</AppButton>
                        </div>


                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
  
<script>
import AppButton from "@/components/ui/buttons/AppButton.vue";
import RezensionElement from "./RezensionElement.vue";
export default {
    components: { AppButton, RezensionElement },

    setup() {
        const rezensionen = [

            {
                hl: 'Markus Maczey',
                sl: 'Creative Managing Director Plan.Net- Group',
                p: 'Philipp: bester Mann. Wir arbeiten seit über 10 Jahren zusammen und ich bin nie enttäuscht worden. Er ist ein absoluter Profi und in der Lage für jedes Problem durch seine kreative, innovative und hingebungsvolle Herangehensweise eine Lösung zu finden. Ein herausragendes Talent in seinem Gebiet, der immer für die bessere Lösung kämpft.'

            },
            {
                hl: 'Elmar Kirschner',
                sl: 'Marketing Manager und Teamleiter NFQ',
                p: 'Schnell, in hoher Qualität und zuverlässig. Genau das bekommt man bei PVM. Im Tagesgeschäft in der Agentur habe ich mit vielen Freelancern und auch anderen Agenturen zusammengearbeitet - am liebsten mit PVM. Den egal ob einfacher HTML5 Banner oder aufwändige Sonderinszinierungen, das Ergebnis hat meine Erwartungen immer übertroffen.'
            },
            {
                hl: 'Christian Thelen',
                sl: 'Head of Adtechnology Flashtalking',
                p: 'Jedes Mal, wenn Philipp eine neue Kampagne startet, ist unsere Freude groß. Das bedeutet nämlich, dass wir uns keine Sorgen um Anzeigen, Clicktags oder Mechaniken machen müssen. Selbst bei extremen Sonderformaten steht er vor keiner Herausforderung. In ganz Deutschland gibt es nur eine Hand voll Personen, die dies bewerkstelligen können – und Philipp ist einer von ihnen. Einfach beeindruckend.'

            },
        ]

        return { rezensionen }
    }
};
</script>
  