<template>
  <div class="c__container c__container--adserver" id="adserver">
    <!-- 
    <div class="c__flex c__flex--1 c__flex--adserver">
      <div class="adserver c__box c__box--adserver">
        <div class="adserver__wrapper c__wrapper">
          <div class="adserver__logocontainer">

            <AdserverBackgroundImage :imgpath="'assets/img/adserver/adserver__logos.png'" />
          </div>
        </div>
      </div>
    </div> -->
    <div class="c__flex c__flex--adserverinfo">
      <div class="kontakt c__box c__box--adserverinfo">
        <div class="adserverinfo__wrapper c__wrapper">
          <div class="lblock__h1 lblock__h1--white text--left">Adserver</div>
          <div class="lblock__content text-left">

            <!-- <div class="lblock__h2 lblock__h2--white">Wir lieben Es</div> -->
            <div class="lblock__p lblock__p--white text-left">

              Ein effektiver Adserver ist das Rückgrat jeder digitalen Werbestrategie. Unsere Expertise und Fachkenntnisse
              ermöglichen es uns, Ihnen eine leistungsstarke Plattform anzubieten, die Ihre Werbekampagnen effizient
              verwaltet und ausliefert. Vertrauen Sie auf unsere Erfahrung und Kompetenz, um Ihre Werbeziele zu erreichen
              und Ihre Reichweite zu maximieren. </div>
            <div class="adserver__logocontainer">

              <AdserverBackgroundImage :imgpath="'assets/img/adserver/adserver__logos.png'" />
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdserverBackgroundImage from './AdserverBackgroundImage.vue';
export default {
  components: { AdserverBackgroundImage },
};
</script>
