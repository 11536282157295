<template>
  <div>

    <div class="swiper__container">
      <div id="swiper__swiper-container" class="swiper__swiper-container swiper-container" ref="swiper" :class="{
        overflowHidden: isSafari
      }">
        <div class="swiper__swiper--wrapper swiper-wrapper">
          <div class="swiper__swiper--slide swiper-slide" v-for="(el) in dataObject" :key="el">
            <div class="swiper__swiper--img swiper-lazy" :data-background="getPath(el)">

              <!-- IFRAME -->
              <iframe id="swiper__adsiframe" class="swiper__adsiframe no-touch-out" :data-src="el.path"></iframe>

              <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            </div>
          </div>
        </div>
      </div>
      <button class="swiper__nextbtn no-touch-out" ref="swiperNextbtn">
        <SwiperBtn class="next" />
      </button>
      <button class="swiper__prevbtn no-touch-out" ref="swiperPrevbtn">
        <SwiperBtn class="prev" />
      </button>
      <div class="swiper-pagination vonmauch" id="swiper-pagination--vonmauch"></div>
    </div>
  </div>
</template>

<script>
// import Swiper JS
import SwiperBtn from "./SwiperBtn.vue";
import Swiper from "swiper/swiper-bundle";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";

import { onMounted, ref } from "vue";
// import GalleryPic from "../gallery/GalleryPic.vue";

import getPathFunction from "@/utilities/composition/getPathFunction";
import { useStore } from 'vuex';

export default {
  props: { dataObject: Array },
  components: { SwiperBtn },

  setup() {
    let eventsSwiper = null;

    const store = useStore();

    const { getPath } = getPathFunction();

    const swiper = ref(null);
    const swiperNextbtn = ref(null);
    const swiperPrevbtn = ref(null);

    const swiperSlide = ref(null); // Ref für das DOM-Element des aktiven Slides
    const activeSlideIndex = ref(null);

    const isSafari = ref(false);



    onMounted(() => {

      function isSafariBrowser() {
        // Überprüfen, ob der Browser den User-Agent enthält "Safari", aber nicht "Chrome" oder "Firefox"
        return /^((?!chrome|android|crios|fxios).)*safari/i.test(navigator.userAgent);
      }

      



      // Beispielaufruf
      if (isSafariBrowser() || store.state.isMobile) {
        isSafari.value = true;
        eventsSwiper = new Swiper(swiper.value, {
          autoplay: {
            delay: 6000,
            disableOnInteraction: true,
          },
          // effect: 'cube',
          watchSlidesVisibility: true,
          preloadImages: false,
          // Enable lazy loading
          lazy: {
            loadPrevNext: true,
          },
          slidesPerView: 1,
          loop: false,
          grabCursor: true,
          navigation: {
            nextEl: swiperNextbtn.value,
            prevEl: swiperPrevbtn.value,
          },
          pagination: {
            el: ".swiper-pagination",
            dynamicBullets: true,
          },
          // Swiper configuration...
          on: {
            slideChange: checkElements,
          },
        });

      } else {
        eventsSwiper = new Swiper(swiper.value, {
          autoplay: {
            delay: 6000,
            disableOnInteraction: true,
          },
          effect: "cube",
          grabCursor: true,
          cubeEffect: {
            shadow: true,
            slideShadows: true,
            shadowOffset: 20,
            shadowScale: 0.94,
          },
          watchSlidesVisibility: true,
          preloadImages: false,
          // Enable lazy loading
          lazy: {
            loadPrevNext: true,
          },
          slidesPerView: 1,
          loop: false,
          navigation: {
            nextEl: swiperNextbtn.value,
            prevEl: swiperPrevbtn.value,
          },
          pagination: {
            el: ".swiper-pagination",
            dynamicBullets: true,
          },
          on: {
            slideChange: checkElements
          },
        });
      }

      // Aktuelles Slide DOM-Element erhalten
      checkElements();

      function checkElements() {
        var currentIndex = eventsSwiper.activeIndex;
        var currentSlide = eventsSwiper.slides[currentIndex];

        // console.log("currentSlide =", currentSlide);
        setSrc(currentSlide);

        // Index für den nächsten Slide
        var nextIndex = currentIndex + 1;
        // Sicherstellen, dass der Index im gültigen Bereich bleibt
        if (nextIndex < eventsSwiper.slides.length) {
          var nextSlide = eventsSwiper.slides[nextIndex];
          // console.log("DOM-Element des nächsten Slides:", nextSlide);
          setSrc(nextSlide); // Laden des nächsten Slides
        } else {
          // console.log("Es gibt keinen nächsten Slide.");
        }

        // Index für den vorherigen Slide
        var prevIndex = currentIndex - 1;
        // Sicherstellen, dass der Index im gültigen Bereich bleibt
        if (prevIndex >= 0) {
          var prevSlide = eventsSwiper.slides[prevIndex];
          // console.log("DOM-Element des vorherigen Slides:", prevSlide);
          setSrc(prevSlide); // Laden des vorherigen Slides
        } else {
          // console.log("Es gibt keinen vorherigen Slide.");
        }

        // // Index für den übernächsten Slide
        // var nextNextIndex = currentIndex + 2;
        // // Sicherstellen, dass der Index im gültigen Bereich bleibt
        // if (nextNextIndex < eventsSwiper.slides.length) {
        //   var nextNextSlide = eventsSwiper.slides[nextNextIndex];
        //   // console.log("DOM-Element des übernächsten Slides:", nextNextSlide);
        //   setSrc(nextNextSlide); // Laden des übernächsten Slides
        // } else {
        //   // console.log("Es gibt keinen übernächsten Slide.");
        // }

        // // Index für den vor-vorherigen Slide
        // var prevPrevIndex = currentIndex - 2;
        // // Sicherstellen, dass der Index im gültigen Bereich bleibt
        // if (prevPrevIndex >= 0) {
        //   var prevPrevSlide = eventsSwiper.slides[prevPrevIndex];
        //   // console.log("DOM-Element des vor-vorherigen Slides:", prevPrevSlide);
        //   setSrc(prevPrevSlide); // Laden des vor-vorherigen Slides
        // } else {
        //   // console.log("Es gibt keinen vor-vorherigen Slide.");
        // }

        var nnIndex = currentIndex + 2;
        // Sicherstellen, dass der Index im gültigen Bereich bleibt
        if (nnIndex < eventsSwiper.slides.length) {
          var nnnSlide = eventsSwiper.slides[nnIndex];
          // console.log("DOM-Element des übernächsten Slides:", nextNextSlide);
          removeSrc(nnnSlide); // Laden des übernächsten Slides
        } else {
          // console.log("Es gibt keinen übernächsten Slide.");
        }

        // Index für den vor-vorherigen Slide
        var ppIndex = currentIndex - 2;
        // Sicherstellen, dass der Index im gültigen Bereich bleibt
        if (ppIndex >= 0) {
          var pppSlide = eventsSwiper.slides[ppIndex];
          // console.log("DOM-Element des vor-vorherigen Slides:", prevPrevSlide);
          removeSrc(pppSlide); // Laden des vor-vorherigen Slides
        } else {
          // console.log("Es gibt keinen vor-vorherigen Slide.");
        }
      }

      function removeSrc(el) {
        var iframe = el.querySelector('.swiper__adsiframe');
        // Überprüfen, ob das src-Attribut bereits gesetzt ist
        iframe.removeAttribute('src');
      }
      function setSrc(el) {
        var iframe = el.querySelector('.swiper__adsiframe');
        // Den Wert des data-src-Attributs lesen
        var dataSrc = iframe.getAttribute('data-src');
        // Überprüfen, ob das src-Attribut bereits gesetzt ist
        if (!iframe.getAttribute('src')) {
          // Den Wert des data-src-Attributs als src-Attribut setzen, wenn es noch nicht geladen wurde
          iframe.setAttribute('src', dataSrc);
        }
      }



      // ENDE ONMOUNT
    });

    // Hole das <iframe>-Element
    // var iframe = document.getElementById('swiper__adsiframe');

    return {
      swiperSlide,store,
      eventsSwiper, getPath, swiper, swiperNextbtn, swiperPrevbtn, activeSlideIndex, isSafari
    };
  },
};
</script>

