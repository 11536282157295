<template>
  <div class="appbutton__outlined" ref="appbutton__outlined">
    <router-link v-if="routerlink" :to="getRouterPath()" class="footer__a">
      <div class="appbutton__outlined__text">
        <slot></slot>
      </div>
    </router-link>
    <div v-else-if="action" class="appbutton__outlined__text">
      <a v-if="action == 'email'" :href="getMail" class="appbutton__outlined__a">
        <slot></slot
      ></a>
      <a v-if="action == 'phone'" :href="getPhone" class="appbutton__outlined__a">
        <slot></slot
      ></a>
      <a v-if="action == 'mobile'" :href="getMobile" class="appbutton__outlined__a">
        <slot></slot>
      </a>
      <a
        v-if="action == 'googlemaps'"
        :href="kontakt.googlemaps"
        class="appbutton__outlined__a"
      >
        <slot></slot>
      </a>
    </div>
    <div v-else class="appbutton__outlined__text">
      <slot></slot>
    </div>
    <div class="appbutton__outlined__marker"></div>
  </div>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";
import contactInformation from "@/utilities/siteinformation/contactInformation";

import { onMounted, ref } from "@vue/runtime-core";
export default {
  props: { routerlink: String, link: String, action: String },
  setup(props) {
    const { prefixPath } = getPathFunction();

    const kontakt = contactInformation;
    const getMail = "mailto:" + kontakt.email;
    const getPhone = "tel:" + kontakt.phone;
    const getMobile = "tel:" + kontakt.mobile;

    function getRouterPath() {
      if (props.routerlink) {
        return prefixPath(props.routerlink);
      } else {
        return "";
      }
    }
    const appbutton__outlined = ref(null);

    const randomNumb = ref(null);
    onMounted(() => {
      if (props.link) {
        appbutton__outlined.value.addEventListener("click", openNewTab);
      }

      function openNewTab(e) {
        e.stopPropagation();
        e.preventDefault();
        randomNumb.value = "?" + Math.floor(Math.random() * 10000000000000000);
        window.open(props.link + randomNumb.value, "_blank");
      }
    });
    return {
      appbutton__outlined,
      randomNumb,
      kontakt,
      getMail,
      getPhone,
      getMobile,
      getRouterPath,
    };
  },
};
</script>
