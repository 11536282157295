<template>
    <div class="c__container c__container--leistung" id="leistungen">

        <div class="c__flex c__flex--leistung">
            <div class="c__box c__box--leistung">
                <div class="leistung__wrapper c__wrapper">
                    <!-- <div class="leistung__h1 leistung__h1--white text--center">leistungen</div> -->
                    <div class="leistung__content">


                        <LeistungsElement v-for="(leistung, index) in leistungen" :key="index">
                            <template v-slot:leistungselement__h1>
                                {{ leistung.hl }}
                            </template>
                            <template v-slot:leistungselement__h2>
                                {{ leistung.sl }}
                            </template>
                            <template v-slot:leistungselement__p>
                                {{ leistung.p }}
                            </template>

                        </LeistungsElement>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import LeistungsElement from './leistungsElement.vue';
// import leistungBackgroundImage from './leistungBackgroundImage.vue';
export default {
    components: { LeistungsElement },


    setup() {
        const leistungen = [

            {
                "hl": "Display-Kampagnen",
                "sl": "Eyecatcher-Anzeigen auf Websites.",
                "p": "Mit unseren Display-Kampagnen bringen wir deine Botschaft auf Websites zum Strahlen! Beeindrucke relevante Nutzer mit optisch ansprechenden Anzeigen auf Millionen von Websites. So erreichst du spielend leicht deine Marketingziele und ziehst die Aufmerksamkeit auf dein Unternehmen."
            },
            {
                "hl": "Mobile Kampagnen",
                "sl": "Maßgeschneidert für unterwegs.",
                "p": "Unsere Mobile Ads sind mehr als nur Werbung. Wir setzen auf innovative Lösungen wie Geotargeting und Store-Finding, um deine Botschaft genau dort zu platzieren, wo sie am meisten Wirkung zeigt. Dabei nutzen wir selbstverständlich Retina-Bilder, um sicherzustellen, dass deine Anzeigen auf jedem mobilen Gerät gestochen scharf und ansprechend wirken. Erreiche deine Zielgruppe gezielt und in höchster Qualität – mit unseren Mobile Ads."
            },
            {
                "hl": "Rich Media Kampagnen",
                "sl": "Video, 3D-Cubes, Swiper. Keine Grenzen.",
                "p": "Rich-Media-Kampagnen bieten eine beeindruckende Vielfalt an Werbemöglichkeiten. Diese Form der Werbung hebt sich deutlich von herkömmlichen Banneranzeigen ab, indem sie eine breite Palette an Medienformaten wie Videos, Audiodateien, Swiper, 3D-Cube-Ads, responsive Ads sowie interaktive Elemente wie Spiele, Umfragen und Formulare einsetzt. Mit unseren umfassenden Lösungen bieten wir eine vielseitige Auswahl, um sämtliche Bedürfnisse und Anforderungen unserer Kunden zu erfüllen."
            },
            {
                "hl": "Dynamic Ads",
                "sl": "Persönliche Werbung, die überzeugt!",
                "p": "Steigere die Effektivität deiner Kampagnen mit dynamischen Anzeigen. Durch die Integration von Media-Feeds bleiben deine Anzeigen stets aktuell und relevant. Deine Werbung erreicht genau die richtige Zielgruppe am richtigen Ort zur richtigen Zeit. Oder aktualisiere deine Preise mit nur wenigen Klicks auf alle bereits angelieferten Werbemittel. So bleiben deine Ads immer up to date."
            },
            {
                "hl": "Dynamic Sitebars",
                "sl": "Full responsive, volle Aufmerksamkeit",
                "p": "Dynamic Sitebars Ads: Der Turbo für deine Werbekampagnen! Direkt in Website-Sidebars platziert, sichern sie höchste Aufmerksamkeit. Flexibel und responsiv passen sie sich jeder Fläche an, steigern die Relevanz und pushen die Conversion-Rate. Nutze jetzt die Power unserer Dynamic Sitebars Ads – optimiert wie keine andere!"
            },
            {
                "hl": "Responsive Ads",
                "sl": "Ein Ad in verschiedenen Breiten",
                "p": "Unsere responsive Ads passen sich flexibel an verschiedene Bildschirmgrößen an und optimieren automatisch ihre Darstellung. Diese Anpassungsfähigkeit ermöglicht es den Anzeigen, sich nahtlos in jede Umgebung zu integrieren, egal ob auf Desktops, Tablets oder Smartphones. Die Vorteile liegen auf der Hand: Eine verbesserte Benutzererfahrung, höhere Klickraten und eine größere Reichweite, da die Anzeigen überall und jederzeit optimal angezeigt werden. Setze auf unsere responsive Ads und erreiche deine Zielgruppe überall und auf jedem Gerät!"
            },
            {
                "hl": "WebGL Ads",
                "sl": "3D? Kein Problem.",
                "p": "WebGL Ads nutzen die leistungsstarke WebGL-Technologie, um interaktive und ansprechende Werbeanzeigen zu erstellen. Mit dynamischen 3D-Grafiken und Animationen können wir deine Botschaft auf einzigartige Weise präsentieren und die Aufmerksamkeit deiner Zielgruppe auf sich ziehen. Verlasse dich auf unsere Expertise, um beeindruckende WebGL Ads zu entwickeln und deine Werbekampagnen auf ein neues Level zu bringen."
            },
            {
                "hl": "Sonderwerbemittel",
                "sl": "Innovation. Creative Code. Development.",
                "p": "Von Spielen bis Umfragen, alles dabei. Dazu spezielle Platzierungen wie Homepagetakeovers. Hohe Sichtbarkeit und einzigartige Präsentation. Nur wenige beherrschen diese Kunst so wie wir. Entdecke die Möglichkeiten und hebe dich ab!"
            },
            {
                "hl": "Video Ads",
                "sl": "Socials. Meta. DOOHs.",
                "p": "Video Ads sind ein kraftvolles Werkzeug, um deine Botschaft zu verbreiten und dein Publikum zu erreichen. Wir bieten maßgeschneiderte Video Ads, die auf Plattformen wie Social Media, Meta und Digital-out-of-Home-Kanälen maximale Wirkung erzielen. Darüber hinaus können wir alles, was wir in HTML5 erstellen können, auch in Videoformaten umsetzen. Verlasse dich auf unsere Expertise, um mit überzeugenden Video Ads deine Zielgruppe zu begeistern und deine Marketingziele zu erreichen."
            },
            {
                "hl": "Verlaengerungen",
                "sl": "Setze uns ein, wo du uns brauchst.",
                "p": "Wir bieten umfassende Dienstleistungen für digitale Werbekampagnen an, von der Konzeption bis zur Umsetzung. Darüber hinaus sind wir spezialisiert auf die Realisierung einzelner Anzeigen, die andere Agenturen nicht umsetzen können. Unser Team verfügt über das Fachwissen und die Kreativität, um selbst die anspruchsvollsten Werbeideen zum Leben zu erwecken. Egal ob es sich um eine vollständige Kampagne oder einzelne Anzeigen handelt – wir sind dein zuverlässiger Partner für herausragende digitale Werbung."
            },
            {
                "hl": "Kreation",
                "sl": "Gestalten. Umbauen. Aufbereiten.",
                "p": "Unsere Expertise liegt in der Kreation, dem Layout und der Gestaltung von Werbematerialien. Mit einem kreativen Team und einem Auge für Design setzen wir deine Ideen in ansprechende visuelle Konzepte um. Von der ersten Skizze bis zum finalen Layout achten wir auf jedes Detail, um sicherzustellen, dass deine Botschaft optimal präsentiert wird. Vertraue auf unsere Kompetenz, um deine Werbematerialien professionell zu gestalten und deine Zielgruppe zu beeindrucken."
            },
            {
                "hl": "Beratung",
                "sl": "TechTalk, Planung, Realisierung",
                "p": "Unsere Beratungsdienstleistungen im Bereich digitale Ads bieten maßgeschneiderte Lösungen für dein Unternehmen. Von der Strategieentwicklung bis zur Umsetzung unterstützen wir dich dabei, deine Werbeziele effektiv zu erreichen. Unsere Experten stehen dir zur Seite, um deine digitale Werbekampagne zu optimieren und maximale Ergebnisse zu erzielen. Verlasse dich auf unsere Erfahrung und Kompetenz, um dein Unternehmen erfolgreich im digitalen Werberaum zu positionieren."
            }




        ]

        return { leistungen }
    }
};
</script>
 
         