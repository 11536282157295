<template>
  <div class="c__container c__container--about" id="about">

    <div class="c__flex c__flex--1 c__flex--about">
      <div class="about c__box c__box--about">
        <div class="about__wrapper c__wrapper">
          <div class="lblock__h1 lblock__h1--white text--left">Digitales Bannering</div>
          <div class="lblock__content text-left ">

            <!-- <div class="lblock__h2 lblock__h2--white">Wir lieben Es</div> -->
            <div class="lblock__p lblock__p--white text-left">

              Bist du bereit, deine digitale Präsenz auf ein neues Level zu bringen? Möchtest du, dass deine Botschaft im
              Internet richtig einschlägt?

              Dann bist du hier genau richtig! Denn wir sind dein ultimatives Team für die Produktion epischer
              HTML5 Banner und das schon <b>seit 2009</b>. Mit unseren studierten und kreativen Köpfen und unserer
              jahrelangen Expertise sind wir
              genau die Spezialisten, die du suchst.
              Wir bringen
              Schwung, Innovation und eine Prise Magie in deine Online-Werbekampagnen.
              Ob direkt für Endkunden oder als Support für Agenturen und Dienstleister: Jeden Auftrag bearbeiten wir mit
              höchster Zuverlässigkeit und Qualitätsanspruch.
              Von animierten Elementen bis hin zu interaktiven Features – wir haben das Know-how, um deine Marke
              erstrahlen  zu lassen! Also, worauf wartest du? Lass uns deine digitalen Träume verwirklichen!
              Denn für die digitale Umsetzung kreativer Ideen schlägt unser
              Herz. <br><br>
              Kann's losgehen?
            </div>
            <div class="btn__container">

              <AppButton class="introb1__btn" :action="'email'">Gib mir Banner!</AppButton>
            </div>


          </div>
        </div>
      </div>
      
    </div>
    <div class="c__flex c__flex--kontakt" id="kontakt">
      <div class="kontakt c__box c__box--kontakt">
        <div class="kontakt__wrapper c__wrapper">
          <div class="lblock__h1 lblock__h1--black text--left">kontakt</div>
          <div class="lblock__content text-left">

            <!-- <div class="lblock__h2 lblock__h2--white">Wir lieben Es</div> -->
            <div class="lblock__p lblock__p--black text-left">

              Wir freuen uns darauf, von Ihnen zu hören! Kontaktieren Sie uns gerne für weitere Informationen, Fragen oder
              Anfragen. Unser Team steht Ihnen jederzeit zur Verfügung, um Ihre Anliegen zu besprechen und
              maßgeschneiderte Lösungen anzubieten. Wir freuen uns auf eine erfolgreiche Zusammenarbeit mit Ihnen!
            </div>

            <div class="btn__container">
              <AppButtonKontakt class="introb1__btn full-width" :action="'email'">
              hello@vonmauch.com</AppButtonKontakt>
              <AppButtonKontakt class="introb1__btn full-width" :action="'phone'">
                +49 170 112 1337</AppButtonKontakt>
              <AppButtonKontakt class="introb1__btn full-width" :action="'googlemaps'">
                Close to Munich</AppButtonKontakt>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/ui/buttons/AppButton.vue";
import AppButtonKontakt from "@/components/ui/buttons/AppButtonKontakt.vue";
export default {
  components: { AppButton,AppButtonKontakt },
};
</script>
