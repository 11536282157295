<template>
  <div class="BGIMG"  ref="BGIMG">
      <div
        class="BGIMG--img"
        :style="[
          { backgroundImage: setBackGroundPath(imgpath) },
          { backgroundPosition: imgposition },
          { backgroundAttachment: imgattachment },
        ]"
      ></div>
  </div>
</template>

<script>

import getPathFunction from "@/utilities/composition/getPathFunction";
export default {
  props: { imgpath: String, imgposition: String, motion: String, animation:String, imgattachment:String },
  setup() {
    const { setBackGroundPath } = getPathFunction();





    return { setBackGroundPath, };
  },
};
</script>


<style scoped lang="scss">

  .BGIMG{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
  }

  .BGIMG--img{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;

    background-position: center;
    background-size: cover;
  }

</style>

