<template>
  <div class="adserverimg"  ref="adserverimg">
      <div

      id="adserverimg--img"
        class="adserverimg--img"
        :style="[
          { backgroundImage: setBackGroundPath(imgpath) },
          { backgroundPosition: imgposition },
          { backgroundAttachment: imgattachment },
        ]"
      ></div>
  </div>
</template>

<script>

import getPathFunction from "@/utilities/composition/getPathFunction";

import gsap from "gsap";
import { onMounted } from 'vue';
export default {
  props: { imgpath: String, imgposition: String, motion: String, animation:String, imgattachment:String },
  setup() {
    const { setBackGroundPath } = getPathFunction();

    onMounted(() => {

      let bg = document.getElementById("adserverimg--img");

      gsap.to(bg, 30, { backgroundPosition: "2808px center", repeat:-1, ease:"none"});
    })





    return { setBackGroundPath, };
  },
};
</script>


<style scoped lang="scss">


</style>

