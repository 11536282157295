<template>
    <div class="c__container c__container--marken" id="markenerfahrung">

        <div class="c__flex c__flex--marken">
            <div class="marken c__box c__box--marken">
                <div class="marken__wrapper c__wrapper">
                    <!-- <div class="marken__h1 marken__h1--white text--center">Markenerfahrung</div> -->
                    <div class="marken__logocontainer">

                        <markenBackgroundImage :imgpath="'assets/img/marken/logos.jpg'" />
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import markenBackgroundImage from './markenBackgroundImage.vue';
export default {
    components: { markenBackgroundImage },
};
</script>
  