<template>
  <div class="header" ref="header">
    <div class="header__wrapper" ref="headerWrapper">
      <div class="header__imgcontainer">
        <background-image :imgpath="imgpath" />
      </div>
      <VideoComponent v-if="!store.state.isMobile" :videopath="videopath" :imgposition="imgposition" />
      <div class="header__content">
        <div class="header__black"></div>
        <div class="header__text">WIR LIEBEN BANNER</div>
      </div>


    </div>
  </div>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";
import { ref } from "@vue/runtime-core";
import VideoComponent from "./VideoComponent.vue";
import BackgroundImage from '../image/BackgroundImage.vue';
import { useStore } from 'vuex';
export default {


  components: { VideoComponent, BackgroundImage },
  props: { videopath: String, imgposition: String, imgpath: String },
  setup() {
    const { setBackGroundPath } = getPathFunction();

    const store = useStore();
    const headerWrapper = ref(null);
    const header = ref(null);

    return { setBackGroundPath, headerWrapper, header, store};
  },
};
</script>

<style lang="scss" scoped></style>
