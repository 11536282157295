<template>
  <div class="headerwelcome c__box">
    <div class="headerwelcome__wrapper" id="home">

      <div class="headerwelcome__content">

        <div class="headerwelcome__flexblock headerwelcome__flexblock--1">

          <div class="headerwelcome__textblock">
            <div class="headerwelcome__textanimation">
              <div id="HWT_0" class="headerwelcome--h1 font-hero ">Von Mauch</div>
              <div id="HWT_1" class="headerwelcome--h1 font-hero HWT">Von Mauch</div>
              <div id="HWT_2" class="headerwelcome--h1 font-hero HWT">kreiert</div>
  
              <div id="HWT_2" class="headerwelcome--h1 font-hero HWT">Animiert</div>
              <div id="HWT_3" class="headerwelcome--h1 font-hero HWT">Coded</div>
              <div id="HWT_4" class="headerwelcome--h1 font-hero HWT">Developed</div>
              <div id="HWT_4" class="headerwelcome--h1 font-hero HWT">verbessert</div>
              <div id="HWT_5" class="headerwelcome--h1 font-hero HWT">Liebt</div>
              <div id="HWT_5" class="headerwelcome--h1 font-hero HWT">HTML5 Ads</div>
            </div>
            <div class="headerwelcome--text"> Willkommen bei Ihrem zuverlässigen Partner für die schnelle Realisierung von
              <b> premium HTML5 Ads</b>. Ob Standardbanner, responsive Ads, dynamic Ads oder Sonderwerbemittel –
              wir sind
              Ihre Experten für jede Art von Online-Werbung.
            </div>
            <div class="tltext__cta" id="tltext__cta">
              <AppButton class="introb1__btn" :action="'email'">Get in touch!</AppButton>
            </div>
          </div>
        </div>
        <div class="headerwelcome__flexblock headerwelcome__flexblock--2">
          <div class="headerwelcome__fancyblock">
            <SwiperAds :dataObject="ads" />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/ui/buttons/AppButton.vue";
import SwiperAds from '@/components/common/swiper/SwiperAds.vue';
import { onMounted } from 'vue';

import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";


export default {
  components: { SwiperAds, AppButton },
  setup() {







    onMounted(() => {
      gsap.registerPlugin(SplitText);

      let color_darkgrey = "#080808";
      // let color_midgrey = "#bbbbbb";
      // let color_lightgrey = "#f1f1f1";
      // let color_lila = "#7f00ff";
      // let color_pink = "#e100ff";
      // let color_yellow = "#fffb49";
      // let color_blue = "#74d5dd";
      // let color_green = "#5eee43";
      // let color_red = "#ff0033";
      // let color_white = "#f1f1f1";

      var splitArray = [];

      let tl_1 = gsap.timeline({ repeat: -1, delay:0});
      tl_1.pause;



      var yourElement = document.querySelectorAll(".HWT");

      for (let index = 0; index < yourElement.length; index++) {
        const element = yourElement[index];
        var split = new SplitText(element);
        split.chars.forEach((item) => {
          item.className = "font-hero";
        });
        splitArray.push(split);

      }
      //       $color-lila:#7f00ff;
      // $color-pink:#e100ff;
      for (let index = 0; index < splitArray.length; index++) {
        const element = splitArray[index];

        gsap.set(element.chars, { scale:0.7,opacity: 0, color: "#7f00ff", },0);
        tl_1.to(element.chars, 0.2, { scale: 1,x:0, opacity: 1, stagger: 0.02, color: color_darkgrey })
          .to(element.chars, 0.2, { scale: 1, opacity: 0, stagger: 0.03, color: "#e100ff" },"+=2");
      }



      // let hw = document.getElementById("home");

      // let tl_hw = gsap.timeline({ repeat: 0 });
      //   // tl_hw.set(hw, { background: `linear-gradient(-45deg,  ${color_white},${color_midgrey})`, ease: "none" })
    



      // let tl_hw = gsap.timeline({ repeat: -1 });
      // let time = 10;
      // tl_hw.pause;
      // tl_hw.set(hw, { background: `linear-gradient(45deg,  ${color_white},${color_lila})`, ease: "none" })
      //   .to(hw, time, { background: `linear-gradient(-45deg, ${color_white}, ${color_yellow})`, ease: "none" })
      //   .to(hw, time, { background: `linear-gradient(-45deg, ${color_white}, ${color_blue})`, ease: "none" })
      //   .to(hw, time, { background: `linear-gradient(-45deg, ${color_white}, ${color_pink})`, ease: "none" })
      //   .to(hw, time, { background: `linear-gradient(-45deg,  ${color_white},${color_green})`, ease: "none" })
      //   .to(hw, time, { background: `linear-gradient(-45deg, ${color_white}, ${color_red})`, ease: "none" })
      //   .to(hw, time, { background: `linear-gradient(-45deg,  ${color_white},${color_lila})`, ease: "none" })

      // tl_hw.set(hw, { background: `linear-gradient(to right, ${color_lila}, ${color_white})`, ease: "none" })
      //   .to(hw, time, { background: `linear-gradient(to right, ${color_yellow}, ${color_white})`, ease: "none" })
      //   .to(hw, time, { background: `linear-gradient(to right, ${color_blue}, ${color_white})`, ease: "none" })
      //   .to(hw, time, { background: `linear-gradient(to right, ${color_pink}, ${color_white})`, ease: "none" })
      //   .to(hw, time, { background: `linear-gradient(to right, ${color_green}, ${color_white})`, ease: "none" })
      //   .to(hw, time, { background: `linear-gradient(to right, ${color_red}, ${color_white})`, ease: "none" })


      // tl_hw.set(hw, { background: `linear-gradient(-45deg, ${color_yellow}, ${color_pink})`, ease: "none" })
      //   .to(hw, time, { background: `linear-gradient(-45deg, ${color_blue}, ${color_red})`, ease: "none" })
      //   .to(hw, time, { background: `linear-gradient(-45deg, ${color_yellow}, ${color_green})`, ease: "none" })
      //   .to(hw, time, { background: `linear-gradient(-45deg, ${color_pink}, ${color_lila})`, ease: "none" })
      //   .to(hw, time, { background: `linear-gradient(-45deg, ${color_blue}, ${color_yellow})`, ease: "none" })
      //   .to(hw, time, { background: `linear-gradient(-45deg, ${color_yellow}, ${color_pink})`, ease: "none" })


    //   tl_hw.set(hw, { background: `linear-gradient(-45deg, ${color_pink}, ${color_yellow})`, ease: "none" })
    // .to(hw, time, { background: `linear-gradient(-45deg, ${color_lila}, ${color_blue})`, ease: "none" })
    // .to(hw, time, { background: `linear-gradient(-45deg, ${color_green}, ${color_yellow})`, ease: "none" })
    // .to(hw, time, { background: `linear-gradient(-45deg, ${color_lila}, ${color_pink})`, ease: "none" })
    // .to(hw, time, { background: `linear-gradient(-45deg, ${color_pink}, ${color_yellow})`, ease: "none" });



    });
    // 
    const ads = [
      {path: "assets/ads/300x600/0/index.html",},
      {path: "assets/ads/300x600/1/index.html",},
      {path: "assets/ads/300x600/2/index.html",},
      {path: "assets/ads/300x600/3/index.html",},
      {path: "assets/ads/300x600/4/index.html",},
      {path: "assets/ads/300x600/5/index.html",},
      {path: "assets/ads/300x600/6/index.html",},
      {path: "assets/ads/300x600/7/index.html",},
      {path: "assets/ads/300x600/8/index.html",},
      {path: "assets/ads/300x600/9/index.html",},
      {path: "assets/ads/300x600/10/index.html",},
      {path: "assets/ads/300x600/11/index.html",},
      {path: "assets/ads/300x600/12/index.html",},
      {path: "assets/ads/300x600/13/index.html",},
     
    ]
    return { ads }
  }
};
</script>


<style scoped lagn="scss"></style>
