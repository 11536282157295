<template>
  <div class="leistungselement">

    <div class="leistungselement__wrapper">
      <div class="leistungselement__content">

        <div class="leistungselement__h1">
          <slot name="leistungselement__h1">Default</slot>

        </div>
        <div class="leistungselement__h2">
          <slot name="leistungselement__h2">Default</slot>

        </div>
        <div class="leistungselement__p">
          <slot name="leistungselement__p">Default</slot>
        </div>
      </div>

      <div class="btn__container">

<AppButtonOutlined class="outlined" :action="'email'">Gib mir Banner!</AppButtonOutlined>
</div>
    </div>
  </div>
</template>

<script>

import AppButtonOutlined from "@/components/ui/buttons/AppButtonOutlined.vue";

export default {
  components: { AppButtonOutlined },
};
</script>

<style scoped>

.btn__container{
  display: none;
}</style>