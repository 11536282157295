<template>
  <div class="firmenimg"  ref="firmenimg">
      <div

      id="firmenimg--img"
        class="firmenimg--img"
        :style="[
          { backgroundImage: setBackGroundPath(imgpath) },
          { backgroundPosition: imgposition },
          { backgroundAttachment: imgattachment },
        ]"
      ></div>
  </div>
</template>

<script>

import getPathFunction from "@/utilities/composition/getPathFunction";

// import gsap from "gsap";
import { onMounted } from 'vue';
export default {
  props: { imgpath: String, imgposition: String, motion: String, animation:String, imgattachment:String },
  setup() {
    const { setBackGroundPath } = getPathFunction();

    onMounted(() => {

      // let bg = document.getElementById("firmenimg--img");

      // gsap.to(bg, 20, { backgroundPosition: "-1439px 0", repeat:-1, ease:"none"});
    })





    return { setBackGroundPath, };
  },
};
</script>


<style scoped lang="scss">


</style>

