<template>
  <div class="IHVideo c__box">
    <div class="IHVideo__wrapper">
      <div class="IHVideo__content">
        <div class="IHVideo__video">
          <header-video-fullscreen :videopath="'assets/videos/16zu9_small.mp4'" :imgpath="'assets/videos/16zu9_small.jpg'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import HeaderVideoFullscreen from "./HeaderVideoFullscreen.vue";
export default {
  components: { HeaderVideoFullscreen },
};
</script>

<style>

.IHVideo__content{
    position: relative;
    width: 100%;
  height: 100%;
}
.IHVideo__video {
  background: black;
  width: 100%;
  height: 100%;
  opacity: 1;
}
</style>
