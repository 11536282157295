<template>
  <div class="c__container c__container--firmen" id="firmen">

    <div class="c__flex c__flex--1 c__flex--firmen">
      <div class="firmen c__box c__box--firmen">
        <div class="firmen__wrapper c__wrapper">
          <div class="firmen__logocontainer">

            <FirmenBackgroundImage :imgpath="'assets/img/firmen/firmen.png'" />
           
          </div>
          <div class="firmen__overlay"></div>
        </div>
      </div>
    </div>
    <div class="c__flex c__flex--danke">
      <div class="kontakt c__box c__box--danke">
        <div class="danke__wrapper c__wrapper">
          <div class="lblock__h1 lblock__h1--black text--left">DANKE!</div>
          <div class="lblock__content text-left">

            <!-- <div class="lblock__h2 lblock__h2--white">Wir lieben Es</div> -->
            <div class="lblock__p lblock__p--black text-left">

              Wir sind stolz darauf, dass zahlreiche Unternehmen seit vielen Jahren auf unsere Dienstleistungen
              zurückgreifen. Diese langjährige Partnerschaft ist ein Beweis für das Vertrauen und die Zufriedenheit
              unserer Kunden. Durch unsere zuverlässigen Lösungen, unseren erstklassigen Service und unsere
              kontinuierliche Unterstützung haben wir es geschafft, langfristige Beziehungen aufzubauen und den Erfolg
              unserer Kunden zu unterstützen. Wir freuen uns darauf, auch in Zukunft gemeinsam mit diesen Unternehmen
              erfolgreich zu sein. </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FirmenBackgroundImage from './FirmenBackgroundImage.vue';
export default {
  components: {  FirmenBackgroundImage },
};
</script>
