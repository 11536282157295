<template>
  <div class="rezensionselement">

    <div class="rezensionselement__wrapper">
      <div class="rezensionselement__content">

        <div class="rezensionselement__h1">
          <slot name="rezensionselement__h1">Default</slot>

        </div>
        <div class="rezensionselement__h2">
          <slot name="rezensionselement__h2">Default</slot>

        </div>
        <div class="rezensionselement__p">
          "<slot name="rezensionselement__p">Default</slot>"
          
        </div>
     
      </div>
    </div>
  </div>
</template>

<script>
export default {


}
</script>

<style></style>